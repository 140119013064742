import React from "react";
import {Route, Routes} from "react-router-dom";
import {Box, Container, Grid} from "@mui/material";
import Contact from "../routes/Contact";
import Essay from "../routes/Essay";
import Research from "../routes/Research";
import Sketch from "../routes/Sketch";
import Home from "../routes/Home";
// import Thoughts from "../routes/Thoughts";
// import Web3 from "../routes/Web3";
import Game from "../routes/Game";

import WhatToLearn from "../routes/essays/WhatToLearn";
import OrnamentAndCrime from "../routes/essays/OrnamentAndCrime";
import WhatQuestionsWorthAnswering from "../routes/essays/WhatQuestionsWorthAnswering";
import MachineryAndRevolution from "../routes/essays/MachineryAndRevolution";
import AWildFeline from "../routes/essays/AWildFeline";
import CrossedNorthAmerica from "../routes/essays/CrossedNorthAmerica";
import GhostInTheMachine from "../routes/essays/GhostInTheMachine";
import AnalyticalTheoryOfHeat from "../routes/essays/AnalyticalTheoryOfHeat";
import TheValueOfExpression from "../routes/essays/TheValueOfExpression";
import ArchitectAndHacker from "../routes/essays/ArchitectAndHacker";
import QuestionsAboutScienceEducation from "../routes/essays/QuestionsAboutScienceEducation";
import ThePoisonOfArrogance from "../routes/essays/ThePoisonOfArrogance";
import AtThisAge from "../routes/essays/AtThisAge";
import NoPurpose from "../routes/essays/NoPurpose";
import GoodNight from "../routes/essays/GoodNight";
import EnglishStudy from "../routes/essays/EnglishStudy";
import MotivePowerOfHeat from "../routes/essays/MotivePowerOfHeat";
import ThinkingFreedomTrait from "../routes/essays/ThinkingFreedomTrait";
import OneAndAnother from "../routes/essays/OneAndAnother";
import DoomButWill from "../routes/essays/DoomButWill";
import InMemoryOfAndreaChegut from "../routes/essays/InMemoryOfAndreaChegut";
import PressureReliefValve from "../routes/essays/PressureReliefValve";
import TheBigCat from "../routes/essays/TheBigCat";
import MechanicalAesthetics from "../routes/essays/MechanicalAesthetics";
import Road from "../routes/essays/Road";
import AGeneration from "../routes/essays/AGeneration";
import TechnologyAndImagination from "../routes/essays/TechnologyAndImagination";
import TwoKindsOfPeople from "../routes/essays/TwoKindsOfPeople";
import Knockout from "../routes/essays/Knockout";
import LessIsMore from "../routes/essays/LessIsMore";
import Instinct from "../routes/essays/Instinct";
import ReplacedOrExtinguished from "../routes/essays/ReplacedOrExtinguished";
import TheValueOfBoredom from "../routes/essays/Boredom";
import LureOfMedium from "../routes/essays/LureOfMedium";
import PathDependency from "../routes/essays/PathDependencyOfTechnology";
import Calendar from "../routes/essays/Calendar";
import IncompleteAndImbalanced from "../routes/essays/IncompleteAndImbalanced";
import Existentialism from "../routes/essays/Existentialism";
import IlyaInterview from "../routes/essays/IlyaInterview";
import ParadigmShift from "../routes/essays/ParadigmShift";
import HowToLearn from "../routes/essays/HowToLearn";
import AntiBullshit from "../routes/essays/AntiBullshit";
import SteveToSteve from "../routes/essays/SteveToSteve";
import Nomad from "../routes/essays/Nomad";
import Frontier from "../routes/essays/EdgeFrontierAndGuerilla";
import Her from "../routes/essays/Her";
import Oversharing from "../routes/essays/Oversharing";
import SelfMotivation from "../routes/essays/SelfMotivation";
import Peril from "../routes/essays/Peril";
import GPTImpact from "../routes/essays/GPTImpact";
import Monkey from "../routes/essays/Monkey";
import Idol from "../routes/essays/Idol";
import CreativityConfidenceDepression from "../routes/essays/CreativityConfidenceDepression";
import Words from "../routes/essays/Words";
import Hometown from "../routes/essays/Hometown";
import Company from "../routes/essays/Company";
import Confession from "../routes/essays/Confession";
import CrossedNorthAmericaAgain from "../routes/essays/CrossedNorthAmericaAgain";
import OfDeath from "../routes/essays/OfDeath";
import SlaveCyborg from "../routes/essays/SlaveAndCyborg";
import OfRespect from "../routes/essays/OfRespect";
import Locomotive from "../routes/essays/Locomotive";
import Rebellion from "../routes/essays/Rebellion";
import Stirrups from "../routes/essays/Stirrups";
import Craftsman from "../routes/essays/Craftsman";
import Agent from "../routes/essays/Agent";
import Imperialism from "../routes/essays/Imperialism";
import Augmentation from "../routes/essays/Augmentation";
import DayOne from "../routes/essays/DayOne";
import OwnWay from "../routes/essays/OwnWay";
import OneProblemOnce from "../routes/essays/OneProblemOnce";
import Brave from "../routes/essays/Brave";
import STEM from "../routes/essays/STEM";
import Conspiracy from "../routes/essays/Conspiracy";
import Irrelevant from "../routes/essays/Irrelevant";
import Mob from "../routes/essays/Mob";
import Borges from "../routes/essays/Borges";
import JSUsage from "../routes/essaysmd/JSUsage";
import Radical from "../routes/essays/Radical";


class Content extends React.Component {
    render() {
        return (
            <Box>
                <Container maxWidth={"xs"}>
                    <Grid container>
                        <Grid item xs={12}>
                            <br/>
                            <div className={"App-content"}>
                                <Routes>
                                    <Route path={"/"} element={<Home/>}></Route>
                                    <Route path={"/sketch"} element={<Sketch/>}/>
                                    <Route path={"/essay"} element={<Essay/>}/>
                                    <Route path={"/research"} element={<Research/>}/>
                                    {/*<Route path={"/thoughts"} element={<Thoughts/>}/>*/}
                                    {/*<Route path={"/web3"} element={<Web3/>}/>*/}
                                    <Route path={"/game"} element={<Game/>}/>
                                    <Route path={"/contact"} element={<Contact/>}/>

                                    {/*Essay Components*/}
                                    <Route path={"/essay/whattolearn"} element={<WhatToLearn/>}/>
                                    <Route path={"/essay/ornamentandcrime"} element={<OrnamentAndCrime/>}/>
                                    <Route path={"/essay/whatquestionsworthanswering"}
                                           element={<WhatQuestionsWorthAnswering/>}/>
                                    <Route path={"/essay/machineryandrevolution"} element={<MachineryAndRevolution/>}/>
                                    <Route path={"/essay/awildfeline"} element={<AWildFeline/>}/>
                                    <Route path={"/essay/crossnorthamerica"} element={<CrossedNorthAmerica/>}/>
                                    <Route path={"/essay/ghostinthemachine"} element={<GhostInTheMachine/>}/>
                                    <Route path={"/essay/analyticaltheoryofheat"} element={<AnalyticalTheoryOfHeat/>}/>
                                    <Route path={"/essay/valueofexpression"} element={<TheValueOfExpression/>}/>
                                    <Route path={"/essay/architectandhacker"} element={<ArchitectAndHacker/>}/>
                                    <Route path={"/essay/qaboutse"} element={<QuestionsAboutScienceEducation/>}/>
                                    <Route path={"/essay/poa"} element={<ThePoisonOfArrogance/>}/>
                                    <Route path={"/essay/atthisage"} element={<AtThisAge/>}/>
                                    <Route path={"/essay/nopurpose"} element={<NoPurpose/>}/>
                                    <Route path={"/essay/goodnight"} element={<GoodNight/>}/>
                                    <Route path={"/essay/englishstudy"} element={<EnglishStudy/>}/>
                                    <Route path={"/essay/mpoh"} element={<MotivePowerOfHeat/>}/>
                                    <Route path={"/essay/think"} element={<ThinkingFreedomTrait/>}/>
                                    <Route path={"/essay/oneandanother"} element={<OneAndAnother/>}/>
                                    <Route path={"/essay/doombutwill"} element={<DoomButWill/>}/>
                                    <Route path={"/essay/inmemoryofandrea"} element={<InMemoryOfAndreaChegut/>}/>
                                    <Route path={"/essay/prv"} element={<PressureReliefValve/>}/>
                                    <Route path={"/essay/bigcat"} element={<TheBigCat/>}/>
                                    <Route path={"/essay/mechanical"} element={<MechanicalAesthetics/>}/>
                                    <Route path={"/essay/road"} element={<Road/>}/>
                                    <Route path={"/essay/generation"} element={<AGeneration/>}/>
                                    <Route path={"/essay/imaginationandtech"} element={<TechnologyAndImagination/>}/>
                                    <Route path={"/essay/twopeople"} element={<TwoKindsOfPeople/>}/>
                                    <Route path={"/essay/knockout"} element={<Knockout/>}/>
                                    <Route path={"/essay/lessismore"} element={<LessIsMore/>}/>
                                    <Route path={"/essay/instinct"} element={<Instinct/>}/>
                                    <Route path={"/essay/replace"} element={<ReplacedOrExtinguished/>}/>
                                    <Route path={"/essay/boredom"} element={<TheValueOfBoredom/>}/>
                                    <Route path={"/essay/lure"} element={<LureOfMedium/>}/>
                                    <Route path={"/essay/pathdependency"} element={<PathDependency/>}/>
                                    <Route path={"/essay/calendar"} element={<Calendar/>}/>
                                    <Route path={"/essay/incomplete"} element={<IncompleteAndImbalanced/>}/>
                                    <Route path={"/essay/existentialism"} element={<Existentialism/>}/>
                                    <Route path={"/essay/ilya"} element={<IlyaInterview/>}/>
                                    <Route path={"/essay/paradigm"} element={<ParadigmShift/>}/>
                                    <Route path={"/essay/howtolearn"} element={<HowToLearn/>}/>
                                    <Route path={"/essay/antibullshit"} element={<AntiBullshit/>}/>
                                    <Route path={"/essay/steve"} element={<SteveToSteve/>}/>
                                    <Route path={"/essay/nomad"} element={<Nomad/>}/>
                                    <Route path={"/essay/frontier"} element={<Frontier/>}/>
                                    <Route path={"/essay/her"} element={<Her/>}/>
                                    <Route path={"/essay/oversharing"} element={<Oversharing/>}/>
                                    <Route path={"/essay/selfmotivation"} element={<SelfMotivation/>}/>
                                    <Route path={"/essay/peril"} element={<Peril/>}/>
                                    <Route path={"/essay/impact"} element={<GPTImpact/>}/>
                                    <Route path={"/essay/monkey"} element={<Monkey/>}/>
                                    {/*<Route path={"/essay/unconstrained"} element={<Unconstrained/>}/>*/}
                                    <Route path={"/essay/idol"} element={<Idol/>}/>
                                    <Route path={"/essay/ccd"} element={<CreativityConfidenceDepression/>}/>
                                    <Route path={"/essay/words"} element={<Words/>}/>
                                    <Route path={"/essay/hometown"} element={<Hometown/>}/>
                                    <Route path={"/essay/company"} element={<Company/>}/>
                                    <Route path={"/essay/confession"} element={<Confession/>}/>
                                    <Route path={"/essay/crossed-north-america-again"} element={<CrossedNorthAmericaAgain/>}/>
                                    <Route path={"/essay/ofdeath"} element={<OfDeath/>}/>
                                    <Route path={"/essay/sac"} element={<SlaveCyborg/>}/>
                                    <Route path={"/essay/ofrespect"} element={<OfRespect/>}/>
                                    <Route path={"/essay/locomotive"} element={<Locomotive/>}/>
                                    <Route path={"/essay/rebellion"} element={<Rebellion/>}/>
                                    <Route path={"/essay/stirrups"} element={<Stirrups/>}/>
                                    <Route path={"/essay/craftsman"} element={<Craftsman/>}/>
                                    <Route path={"/essay/agent"} element={<Agent/>}/>
                                    <Route path={"/essay/imperialism"} element={<Imperialism/>}/>
                                    <Route path={"/essay/augmentation"} element={<Augmentation/>}/>
                                    <Route path={"/essay/dayone"} element={<DayOne/>}/>
                                    <Route path={"/essay/ownway"} element={<OwnWay/>}/>
                                    <Route path={"/essay/problemonce"} element={<OneProblemOnce/>}/>
                                    <Route path={"/essay/brave"} element={<Brave/>}/>
                                    <Route path={"/essay/stem"} element={<STEM/>}/>
                                    <Route path={"/essay/conspiracy"} element={<Conspiracy/>}/>
                                    <Route path={"/essay/irrelevant"} element={<Irrelevant/>}/>
                                    <Route path={"/essay/mob"} element={<Mob/>}/>
                                    <Route path={"/essay/borges"} element={<Borges/>}/>
                                    <Route path={"/essay/jsusage"} element={<JSUsage/>}/>
                                    <Route path={"/essay/radical"} element={<Radical/>}/>
                                </Routes>
                                {/*<h1>Main Page!</h1>*/}
                            </div>
                        </Grid>
                    </Grid>
                </Container>
            </Box>


        )
    }
}

export default Content;
