import React from "react";
import {Box, Container, Grid} from "@mui/material";


class Footer extends React.Component {
    render() {
        return (
            <Box>
                <Container maxWidth={"xs"}>
                    <Grid container>
                        <Grid item xs={12}>
                            <div className={"App-footer"}>
                                <br/>
                                <p style={{
                                    margin: "0px",
                                    fontSize: "9px",
                                }}
                                   className={"App-footer-copyright"}
                                >
                                    ©2025 K.C. All Rights Reserved.
                                    {/*<a style={{color: "lightgrey", alignContent: "right", textDecoration: "none"}}*/}
                                    {/*    href="https://beian.miit.gov.cn"*/}
                                    {/*    rel="noreferrer noopener" target="_blank"*/}
                                    {/*>*/}
                                    {/*    粤ICP备2024284371号-2 |*/}
                                    {/*</a>*/}
                                    {/*粤公网安备44030002005238号*/}
                                </p>
                                <br/>
                            </div>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        )
    }
}

export default Footer;
